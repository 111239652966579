import * as React from "react";

export interface UnorderedListProps<T> {
	data: T[];
	keyPrefix: string;
	renderItem: (data: T, index: number) => JSX.Element;
	renderKey: (prefix: string, data: T, index: number) => string;
}

const UnorderedList = <T extends unknown>(props: UnorderedListProps<T>) => {
	const { keyPrefix, renderItem, renderKey } = props;
	return (
		<ul>
			{props.data.map((listItem, index) => {
				return (
					<li key={renderKey(keyPrefix, listItem, index)}>
						{renderItem(listItem, index)}
					</li>
				);
			})}
		</ul>
	);
};

export default UnorderedList;
