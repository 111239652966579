import * as React from "react";
import Layout from "@components/Layout";
import Row from "@components/Container/Row";
import Column from "@components/Container/Column";
import FixedImage from "@components/Image/FixedImage";
import YellowButton from "@components/Button/YellowButton";
import H2 from "@components/Text/H2";
import P from "@components/Text/P";
import UnorderedList from "../components/Container/UnorderedList";
import { useStaticQuery, graphql, withPrefix, PageProps } from "gatsby";
import { AboutData, AboutOutGoingLink } from "../data/about";
import "@themes/layout.scss";
import SEO from "@components/seo";

const renderNetworkLinks = (data: AboutOutGoingLink, index: number) => {
    const { name, link, caption } = data;
    return (
        <span>
            <a target="_blank" href={link}>
                {name}
            </a>
            {` `} - {caption}
        </span>
    );
};

const renderNetworkLinkKey = (
    prefix: string,
    data: AboutOutGoingLink,
    index: number
) => {
    const { name } = data;
    return `${prefix}_${name}_${index}`;
};

const About = (props: PageProps) => {
    const query = useStaticQuery(graphql`
        query {
            profilePicture: file(relativePath: { eq: "new_hon.jpg" }) {
                childImageSharp {
                    fixed(width: 200, height: 200) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `);
    const { path } = props;

    return (
        <Layout>
            <SEO
                title="About Me"
                description="Some biographic description about Ng Chen Hon"
                keywords={[
                    "Ng Chen Hon",
                    "About Me",
                    "Ng Chen Hon's about me",
                    "Mutatedbread's about me",
                ]}
                pagePath={path}
            />
            <Row>
                <Column>
                    <H2>{AboutData.intro}</H2>
                    <P>{AboutData.para1}</P>
                </Column>
                <div id="about-picture-column">
                    <FixedImage
                        src={query.profilePicture.childImageSharp.fixed}
                    />
                </div>
            </Row>
            <Row>
                <Column>
                    <div id="mobile-centeralize">
                        <YellowButton
                            isDownload={true}
                            href={withPrefix("resume.pdf")}
                        >
                            Download my résumé
                        </YellowButton>
                    </div>
                    <P>{AboutData.para2}</P>
                    <P style={{ marginBottom: 0 }}>
                        {AboutData.para3_1}
                        <a target="_blank" href={AboutData.para3_link.href}>
                            {AboutData.para3_link.text}
                        </a>
                        {AboutData.para3_2}
                    </P>
                </Column>
            </Row>
            <Row>
                <Column>
                    <P>{AboutData.para4}</P>
                    <UnorderedList
                        renderItem={renderNetworkLinks}
                        renderKey={renderNetworkLinkKey}
                        data={AboutData.outGoingLinks}
                        keyPrefix="about_out_going_link"
                    />
                </Column>
            </Row>
        </Layout>
    );
};

export default About;
