import * as React from "react";
import "@themes/text.scss";
import { TextProps } from "./TextProps";

const P = (props: TextProps) => {
	const { className, children, ...textProps } = props;
	return (
		<p className={["text-normal", className].join(" ")} {...textProps}>
			{children}
		</p>
	);
};

export default P;
