export interface AboutOutGoingLink {
    name: string;
    link: string;
    caption: string;
}

export const AboutData = {
    intro: "Hey there, I am Ng Chen Hon.",
    para1:
        "I'm a Software Engineer living in Singapore, with an interest at architecuting a service and productising an idea.",
    para2:
        "I graduated from Multimedia University from Malaysia in Computer Science with a Game Development major. I've worked at a few companies on backend, frontend, and React Native mobile app development.",
    para3_1: "Now, I am working at ",
    para3_link: {
        text: "Ascenda Loyalty",
        href:
            "https://primetechnologies.com.sg/https://www.ascendaloyalty.com/",
    },
    para3_2:
        " building and solutoning backend services and APIs to process high volume of transactions.",
    para4:
        "You can also find me at these places, my username is usually mutatedbread.",
    outGoingLinks: [
        {
            name: "Linkedin",
            link: "https://www.linkedin.com/in/ngchenhon/",
            caption: "for work, 工作, kerja",
        },
        {
            name: "Github",
            link: "https://github.com/MutatedBread",
            caption: "i put some codes here",
        },
        {
            name: "Dev.to",
            link: "https://dev.to/mutatedbread",
            caption: "place for articles",
        },
    ] as AboutOutGoingLink[],
};

// 1. Linkedin
// 2. Github
// 3. Dev.to
