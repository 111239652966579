import * as React from "react";
import "@themes/text.scss";
import { TextProps } from "./TextProps";

const H2 = (props: TextProps) => {
	const { children, className, ...textProps } = props;
	return (
		<h2 className={["text-heading2", className].join(" ")} {...textProps}>
			{children}
		</h2>
	);
};

export default H2;
